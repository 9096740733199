import { map, switchMap } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';
import { EventsService } from '@common/services/events.service';

@Component({
  selector: 'validate-event-qr',
  standalone: false,
  templateUrl: './validate-event-qr.component.html',
  styleUrl: './validate-event-qr.component.scss',
})
export class ValidateEventQrComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public events: EventsService,
    public activatedRoute: ActivatedRoute
  ) {}

  public loading = true;
  public message = ''

  ngOnInit(): void {
    const paramsAndQuery = this.activatedRoute.params.pipe(
      switchMap((params) => {
        return this.activatedRoute.queryParams.pipe(
          map((query) => {
            return { params, query };
          })
        );
      })
    );
    paramsAndQuery.subscribe(({ params, query }) => {
      if (query['token'] && params['id']) {
        this.events.validateEventQr(params['id'], query['token']).subscribe({
          next: (data) => {
            this.loading = false;
            this.message = 'Katılımınız onaylandı.';
          },
          error: (error) => {
            console.log(error);
            this.loading = false;
            this.message = 'Katılımınız onaylanamadı.';
          },
        });
      }
    });
  }
}
