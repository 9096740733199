<main-content>
  <div
    class="container"
    style="
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="card" style="width: 18rem">
      <div class="card-body">
        <h5 class="card-title">Hesap Silme</h5>
        <p class="card-text">Hesabınızı silmek istediğinize emin misiniz?</p>
        <!-- Checkbox for all data-->
        <section class="example-section">
          <mat-checkbox class="example-margin">Tüm verileri sil</mat-checkbox>
        </section>
        <button class="btn btn-danger" (click)="deleteAccount()">
          Hesabı Sil
        </button>
      </div>
    </div>
  </div>
</main-content>
