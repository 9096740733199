import { Component } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { AppHttpClient } from '@common/core/http/http.service';
import { Toast } from '@common/core/toast/toast/toast.service';

@Component({
  selector: 'delete-account',
  standalone: false,
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.scss',
})
export class DeleteAccountComponent {
  constructor(
    private http: AppHttpClient,
    private authService: AuthService,
    private toast: Toast
  ) {}

  public deleteAccount() {
    this.http.delete('users/me').subscribe(() => {
      this.authService.logout();
      this.toast.open('Hesabınız başarıyla silindi.');
    });
  }
}
