@if (loading) {
<div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
  <center-loading></center-loading>
</div>
} @else {
<div class="d-flex flex-column justify-content-center align-items-center gap-10" style="height: 100vh;">
  <h1>{{ message }}</h1>
  <a mat-button [routerLink]="['/']">Ana sayfaya dön</a>
</div>
}
