import { PurchaseEventTicketComponent } from './events/purchase-event-ticket/purchase-event-ticket.component';
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExploreComponent } from 'src/app/site/explore/explore.component';
import { ProjectsComponent } from 'src/app/site/projects/projects.component';
import { CommunitiesComponent } from './communities/communities.component';
import { CompaniesComponent } from './companies/companies.component';
import { FriendsComponent } from './friends/friends.component';
import { MainHomeComponent } from './main-home/main-home.component';
import { PeopleComponent } from './people/people.component';
import { SavedComponent } from './saved/saved.component';
import { UserProfileComponent } from './profiles/user-profile/user-profile.component';
import { EventsComponent } from './events/events.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SchoolComponent } from './school/school.component';
import { SearchResultsComponent } from './search-results/search-results.component';

import { CompanyComponent } from './profiles/profiles/company/company.component';
import { JobPostingComponent } from './job-posting/job-posting.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { MessagesComponent } from './messages/messages.component';
import { CompaniesExtendedComponent } from './companies/companies-extended/companies-extended.component';
import { PeopleExtendedComponent } from './people/people-extended/people-extended.component';
import { ProjectsExtendedComponent } from './projects/projects-extended/projects-extended.component';
import { CommunitiesExtendedComponent } from './communities/communities-extended/communities-extended.component';
import { EventsExtendedComponent } from './events/events-extended/events-extended.component';
import { UserPostsComponent } from './profiles/user-profile/user-posts/user-posts.component';
import { UserHighlightsComponent } from './profiles/user-profile/user-highlights/user-highlights.component';
import { DiscoveryCatalogComponent } from './discovery-catalog/discovery-catalog.component';
import { ServiceErrorComponent } from './service-error/service-error.component';
import { PageComponent } from './page/page.component';
import { hasPermission } from '@common/core/guards/has-permission';
import { loggedIn } from '@common/auth/logged-in.service';
import { CheckoutComponent } from './checkout/checkout.component';
import { EventProfileComponent } from './profiles/profiles/event-profile/event-profile.component';
import { PostPageComponent } from './posts/post-page/post-page.component';
import { BlogsComponent } from './blog/blogs/blogs.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { JobPostingDetailsComponent } from './job-posting/job-posting/job-posting-details/job-posting-details.component';
import { JobPostingApplicationComponent } from './job-posting/job-posting-application/job-posting-application.component';
import { ProjectDetailsComponent } from './projects/project-details/project-details.component';
import { CommuityProfileComponent } from './profiles/profiles/commuity-profile/commuity-profile.component';
import { ErrorPageComponent } from '@common/ui/error-page/error-page.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UserHomeComponent } from './main-home/user-home/user-home.component';
import { PersonalCalendarWeekComponent } from './personal-calendar/personal-calendar-week/personal-calendar-week.component';
import { PersonalCalendarMonthComponent } from './personal-calendar/personal-calendar-month/personal-calendar-month.component';
import { PersonalCalendarListComponent } from './personal-calendar/personal-calendar-list/personal-calendar-list.component';
import { ValidateEventQrComponent } from './events/validate-event-qr/validate-event-qr.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';

const routes: Routes = [
  {
    path: 'home',
    component: MainHomeComponent,
    data: { title: 'Ana sayfa', navigation: 'home' },
  },
  { path: '', component: MainHomeComponent },
  {
    path: 'explore',
    component: UserHomeComponent,
    data: { title: 'Ana Sayfa', navigation: 'home' },
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Projeler',
      navigation: 'projects',
    },
  },
  {
    path: 'communities',
    component: CommunitiesComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Topluluklar',
      navigation: 'communities',
    },
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Şirketler',
      navigation: 'companies',
    },
  },
  {
    path: 'people',
    component: PeopleComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Kişiler',
      navigation: 'people',
      willSetSeo: true,
    },
  },
  {
    path: 'friends',
    component: FriendsComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Arkadaşlar',
      navigation: 'friends',
    },
  },
  { path: 'saved', component: SavedComponent, canActivate: [loggedIn] },
  {
    path: 'my-profile',
    component: UserProfileComponent,
    canActivate: [loggedIn],
  },
  {
    path: 'user-profile/:username',
    component: UserProfileComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Profil',
      navigation: 'profile',
    },
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Etkinlikler',
      navigation: 'events',
    },
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Bildirimler',
      navigation: 'notifications',
    },
  },

  { path: 'school/:id', component: SchoolComponent, canActivate: [loggedIn] },

  {
    path: 'search',
    component: SearchResultsComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Arama Sonuçları',
      navigation: 'search',
    },
  },

  {
    path: 'job-posting/:id',
    component: JobPostingDetailsComponent,
    canActivate: [loggedIn],
    data: {
      title: 'İş İlanı',
      navigation: 'job-posting',
    },
  },

  {
    path: 'job-posting/:id/application',
    component: JobPostingApplicationComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Başvuru',
      navigation: 'job-posting',
    },
  },
  {
    path: 'job-posting',
    component: JobPostingComponent,
    canActivate: [loggedIn],
    data: {
      title: 'İş İlanları',
      navigation: 'job-posting',
    },
  },

  {
    path: 'service-error',
    component: ServiceErrorComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'event-details',
    component: EventDetailsComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Etkinlik',
      navigation: 'event-details',
    },
  },

  {
    path: 'community/:id',
    component: CommuityProfileComponent,
    canActivate: [loggedIn],
  },

  { path: 'company/:id', component: CompanyComponent, canActivate: [loggedIn] },

  { path: 'messages', component: MessagesComponent, canActivate: [loggedIn] },

  {
    path: 'companies-extended',
    component: CompaniesExtendedComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'people-extended',
    component: PeopleExtendedComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'projects-extended',
    component: ProjectsExtendedComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'communities-extended',
    component: CommunitiesExtendedComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'events-extended',
    component: EventsExtendedComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'user-posts',
    component: UserPostsComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'user-highlights',
    component: UserHighlightsComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'discovery-catalog',
    component: DiscoveryCatalogComponent,
    canActivate: [loggedIn],
    data: {
      title: 'Keşif Kataloğu',
      navigation: 'discovery-catalog',
    },
  },

  {
    path: 'page/:slug',
    component: PageComponent,
  },
  {
    path: 'events/:id/validate',
    component: ValidateEventQrComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'delete-account',
    component: DeleteAccountComponent,
    canActivate: [loggedIn],
  },

  {
    path: 'checkout',
    component: CheckoutComponent,
  },
  {
    path: 'event/:id',
    component: EventDetailsComponent,
  },
  {
    path: 'project/:id',
    component: ProjectDetailsComponent,
  },
  {
    path: 'events/:id/purchase/:applicationId',
    component: PurchaseEventTicketComponent,
  },
  {
    path: 'post/:id',
    component: PostPageComponent,
  },
  {
    path: 'blog',
    component: BlogsComponent,
    data: { title: 'Blog', navigation: 'blog' },
  },
  {
    path: 'blog/:id',
    component: BlogDetailsComponent,
  },
  {
    path: 'calendar/week',
    component: PersonalCalendarWeekComponent,
  },
  {
    path: 'calendar/list',
    component: PersonalCalendarListComponent,
  },
  {
    path: 'calendar/month',
    component: PersonalCalendarMonthComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
    data: { title: '404', navigation: '404' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SiteRoutingModule {}
