import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { Observable, map, Subject, BehaviorSubject, elementAt } from 'rxjs';
import { Pagination } from 'src/app/models/pagination';
import { Post } from 'src/app/models/post';
import { FormArray, FormBuilder } from '@angular/forms';
import { UploadService } from '@common/core/upload/upload.service';
import { HomeService } from '@common/services/home.service';
import { FollowersService } from '@common/services/followers.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FeedbackOpenerServiceService } from '@common/services/feedback-opener-service.service';
import { FeedbackFormComponent } from '@common/ui/feedback-form/feedback-form.component';
import { PollDialogComponent } from '@common/ui/poll-dialog/poll-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContentEditingService } from 'src/app/admin/content-editing/content-editing.service';
import { Router } from '@angular/router';
import { PostService } from 'src/app/admin/services/post.service';
import { PostsService } from '../../posts/posts.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { isPlatformServer } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss'],
  host: { ngSkipHydration: 'true' },
})
export class UserHomeComponent implements OnInit {
  public posts: Post[] = [];

  public page = 1;
  public last_page = 1;
  public loading = true;

  constructor(
    public auth: AuthService,
    private breakPointObserver: BreakpointObserver,
    public homeService: HomeService,
    public fb: FormBuilder,
    private uploadService: UploadService,
    public followerService: FollowersService,
    private bottomSheetService: MatBottomSheet,
    private feedOpenerService: FeedbackOpenerServiceService,
    public dialog: MatDialog,
    public contentEditing: ContentEditingService,
    public router: Router,
    public postService: PostsService,
    public cd: ChangeDetectorRef,
    public cookies: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public getLocalPosts() {
    try {
      const data = JSON.parse(this.cookies.get('posts'));
      return data;
    } catch (error) {}
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    //In chrome and some browser scroll is given to body tag
    let pos = document.documentElement.scrollTop;

    let max =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    //console.log(pos, max);
    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if (pos >= max - 100 && !this.loading) {
      if (this.page < this.last_page) {
        this.loading = true;
        this.page++;
        this.loadPage();
      }
    }
  }

  public get postForm() {
    return this.contentEditing.createPostForm;
  }

  ngOnInit(): void {
    this.feedOpenerService.changeStateFeedBack.subscribe((state: boolean) => {
      if (state) {
        this.bottomSheetService.open(FeedbackFormComponent);
      }
    });

    this.loadPage();
  }

  public getPosts() {
    return this.homeService.getPosts(this.page).pipe(
      map((res) => {
        this.cookies.set('posts', JSON.stringify(res));
        return res;
      })
    );
  }

  public loadPage() {
    if (this.page != 1 && isPlatformServer(this.platformId)) {
      return;
    }
    this.getPosts().subscribe({
      next: (res) => {
        this.page = res.current_page;
        this.last_page = res.last_page;
        this.loading = false;
        this.posts = [...this.posts, ...res.data];
        //this.cd.detectChanges();
      },
    });
  }

  public summary$ = this.homeService.getSummary().pipe(map((res) => res.data));
  public youMightKnow$ = this.homeService
    .youMightKnow()
    .pipe(map((res) => res.data));

  public submitPost() {
    this.router.navigate(['/admin', 'content-editing']);
  }

  public submitPost2() {
    const text = this.postForm.get('text').value;
    if (!text) {
      return;
    }
    const data: any = {
      text,
      images: this.images.value,
      videos: this.videos.value,
      notify: ['PUBLIC'],
      contributors: [],
      postLanguage: 'Turkish',
      postLocation: 'Turkey',
      poll: this.postForm.get('poll').value,
    };
    this.postForm.reset();
    this.homeService
      .submitPost(this.postForm.value as any)
      .subscribe((res) => {});
  }

  public reportedIds = [];

  public onIntersection(
    {
      target,
      visible,
    }: {
      target: Element;
      visible: boolean;
    },
    post: Post
  ): void {
    if (visible && !this.reportedIds.includes(post?.id)) {
      this.reportedIds.push(post?.id);
      this.postService.reportView(post).subscribe();
    }
  }

  public toggle($event, user) {
    $event.stopPropagation();
    this.followerService.toogleExtended(user).subscribe();
  }

  public get images() {
    return this.postForm.get('images') as FormArray;
  }

  public get videos() {
    return this.postForm.get('videos') as FormArray;
  }

  public pickImage() {
    this.uploadService
      .openUploadDialog$('image', true, {
        allowCrop: false,
      })
      .subscribe((files) => {
        for (let file of files) {
          this.images.push(this.fb.control(file));
        }
      });
  }

  public pickVideo() {
    this.uploadService
      .openUploadDialog$('video', true, {
        allowCrop: false,
      })
      .subscribe((files) => {
        for (let file of files) {
          this.videos.push(this.fb.control(file));
        }
      });
  }

  public testClick() {}

  public isMobile = this.breakPointObserver
    .observe(Breakpoints.Small)
    .pipe(map((result) => result.matches));

  public upload(files: FileList) {
    this.uploadService.upload(files[0]);
  }
}
